import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Logo, Container, Info, FaceAdd } from "./styles/page.style";
import {
  MdOutlineEditNote,
  MdOutlineNotificationsActive,
} from "react-icons/md";
import { TbFaceId } from "react-icons/tb";
import logo from "../../assets/images/logo.png";
import helpIcon from "../../assets/images/help-icon.png";
import sensorIcon from "../../assets/images/sensor-icon.png";
import { Button, ButtonToolbar, IconButton, Tooltip, Whisper } from "rsuite";
import HelpOutlineIcon from "@rsuite/icons/HelpOutline";
import Trigger from "../../components/trigger";
import { StoresContext } from "../../stores";
import ModalInfoGeoLocation from "../../components/modal_info_geolocation";
import ModalInfoTuto from "../../components/modal_info_tuto";
import ModalExpired from "../../components/modal_expired";
import Modal_add_face from "../../components/modal_add_face";

const PageTriggering = () => {
  const {
    authorizationStore,
    visitorStore,
    getGeoLocationStore,
    triggerStore,
  } = useContext(StoresContext);

  const [open, setOpen] = useState(false);
  const [face, setFace] = useState(false);

  const toggleOpen = () => setOpen(!open);
  const toggleFace = () => setFace(!face);

  useEffect(() => {
    if (
      authorizationStore.validated &&
      localStorage.getItem("@token_authorization")
    )
      visitorStore.getByAutorizationId(
        localStorage.getItem("@token_authorization") ?? ""
      );
  }, [authorizationStore.validated]);

  useEffect(() => {
    if (authorizationStore.validated) getGeoLocationStore.getGeoLocation();
  }, [authorizationStore.validated]);

  return (
    <Container>
      <div className="header">
        <div className="d-flex justify-content-center">
          <Logo src={logo} />
        </div>
        <div className="d-flex">
          <p className="title-header">Olá,</p>
          <p className="subtitle-header">
            {authorizationStore.visitor?.nome.split(" ")[0]}
          </p>
        </div>
        <p
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "12px",
            padding: 4,
          }}
        >
          Esta é sua chave virtual para acesso ao condomínio. Selecione o portão
          que deseja acionar para entrar.
        </p>

        {authorizationStore.visitor?.facial && (
          <div
          onClick={toggleFace}
            style={{
              backgroundColor: "#E0E0E0",
              width: "100%",
              maxWidth: "167px",
              display: "flex",
              padding: 4,
              gap: 4,
              borderRadius: "50px",
              marginTop: 20,
              boxShadow: "0px 4px 4px 0px #00000057",
            }}
          >
            <img src={sensorIcon} alt="Imagem de facial" />
            <h4
              style={{ fontSize: "12px", color: "#0A1A27", fontWeight: "bold" }}
            >
              Cadastre sua facial
            </h4>
          </div>
        )}

       
      </div>

      <Trigger />

      {triggerStore.expired ? (
        ""
      ) : (
        <Info
          onClick={toggleOpen}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <img
            src={helpIcon}
            className="icon-help"
            style={{ marginRight: "50px" }}
          />
          <IconButton
            icon={<HelpOutlineIcon style={{ fontSize: 25, color: "#FFF" }} />}
            style={{
              background: "#0A1A29",
              borderRadius: 60,
              width: 50,
              height: 50,
            }}
            appearance="primary"
            circle
          />


        </Info>
      )}


      {!getGeoLocationStore.accepted ? (
        <ModalInfoGeoLocation
          show={!getGeoLocationStore.accepted}
          hide={() => {}}
        />
      ) : (
        ""
      )}

      <ModalInfoTuto show={open} hide={toggleOpen} />
      <Modal_add_face show={face} hide={toggleFace} />
      <ModalExpired show={triggerStore.expired} hide={() => {}} />
    </Container>
  );
};

export default observer(PageTriggering);
