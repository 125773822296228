import { createGlobalStyle } from 'styled-components';


export const GlobalStyles = createGlobalStyle`
  body{
    font-family: Arial, sans-serif;
    font-weight: 50;
    text-decoration: none;
    margin: 0;
    padding: 0;
    background-color: rgba(240, 240, 240, 1);
  }
`;

export default GlobalStyles;