import { observer } from 'mobx-react-lite';
import React, { useRef, useState, useContext, FC, useEffect } from 'react'
import { Modal } from 'rsuite';
import token from '../../assets/images/token_expired.png';
import logo from '../../assets/images/logo.png';
import { Container, Footer } from './styles/component.style';
import { StoresContext } from '../../stores';
import { MdOutlineNotificationsActive } from 'react-icons/md';
import { BsX } from 'react-icons/bs';

interface Props
{
  show: boolean;
  hide: () => void;
}

const ModalExpired: FC<Props> = ({ show, hide }) => {

  const { triggerStore } = useContext(StoresContext);

  useEffect(() =>
  {
    if(triggerStore.expired)
    {
      setTimeout(() =>
      {
        window.location.replace('/notoken');
        localStorage.clear();
      }, 5000)
    }
  }, [triggerStore.expired]);

  return (
    <Modal open={show} onClose={hide} >
      <Modal.Body style={{ alignSelf: 'center', height: '100vh'}}>
          <Container>
            <div>
              <div className='d-flex justify-content-center'>
                <img src={token} className='img'/>
              </div>
              <p className='title'>Obrigado por utilizar nossa chave virtual!</p>
              <p className='subtitle'>
                Volte sempre!
              </p>
            </div>
            <Footer>
              <img src={logo} className='logo' />
            </Footer>
          </Container>
      </Modal.Body>
    </Modal>
  )
}

export default observer(ModalExpired);
