import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import background from '../../../assets/images/background-loading.png';

export const Container = styled.div`
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: #fff;

    .gif
    {
        height: 340px;
        margin-top:  0px;
    }
`;

export const CardBottom = styled.div`
    background: white;
    position: fixed;
    margin-top: -20px;
    bottom: 20px;
    width: 100vw;
    height: 35vh;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding: 35px;

    .title
    {
        color: rgba(0,0,0,0.8);
        font-size: 20pt;
        font-family: 'Exo', sans-serif;
        font-weight: bold;

    }
    .subtitle
    {
        margin: 0;
        color: rgba(0,0,0,0.8);
        font-weight: 700;
        font-size: 10pt;
    }
    .logo
    {
        height: 70px;
    }
    .card-icon
    {
        background: #004d93;
        height: 50px;
        width: 50px;
        border-radius: 60px;
        box-shadow: 1px 3px 6px rgba(0,0,0,0.5);
    }
    .icon-load
    {
        align-self: center;
    }
`;
