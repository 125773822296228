import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_MS_ACIONAMENTO;
var api = axios.create({ baseURL });

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('@token');
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default api;