import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import background from '../../../assets/images/background-loading.png';

export const Container = styled.div`
    padding: 10px;
    .img
    {
        height: 120px;
    }
    .picture
    {
        border-radius: 10px;
    }
    .title
    {
        margin: 0;
        text-align: center;
        color: #004d93;
        font-weight: 550;
        font-size: 18pt;
    }
    .subtitle
    {
        text-align: center;
        margin: 0;
        font-size: 10pt;
    }
    .content
    {
        font-size: 13pt;
    }
    .info-loading
    {
        margin-top: 40px;
        text-align: center; 
        font-size: 15pt;
    }
    .title-response
    {
        margin: 0;
        text-align: center;
        color: #fff;
        font-weight: 550;
        font-size: 15pt;
        padding: 10px;
        border-radius: 11px;
        background-color: #004d93;
        margin-bottom: 5px;
    }
    .info-text
    {
        margin: 0;
        font-size: 11pt;
        margin-top: 15px;
    }
`;

export const CardTemFacial = styled.div`
    padding: 40px;
    .title
    {
        margin: 0;
        text-align: center;
        color: #004d93;
        font-weight: 550;
        font-size: 18pt;
    }
    .subtitle
    {
        text-align: center;
        margin: 0;
        font-size: 12pt;
        margin-top: 20px;
    }
`;

export const CardLoad = styled.div`
    padding: 10px;
    overflow-y: scroll;
    height: 50vh;
    .img
    {
        height: 160px;
        margin-top: 30px;
    }
    .title
    {
        margin: 0;
        text-align: center;
        color: #004d93;
        font-weight: 550;
        font-size: 15pt;
    }
   
    .item-response
    {
        margin: 0;
        color: #004d93;
        font-size: 11pt;
        margin-bottom: 5px;
    }


    .title-collection
    {
        margin: 0;
        color: #004d93;
        font-weight: bold;
        font-size: 12pt;
    }
    .subtitle-collection
    {
        margin: 0;
        color: rgba(0,0,0,0.7);
        font-size: 10pt;
    }
    .title-response
    {
        margin: 0;
        text-align: center;
        color: #fff;
        font-weight: 550;
        font-size: 15pt;
        padding: 10px;
        border-radius: 11px;
        background-color: #004d93;
        margin-bottom: 5px;
    }
`;