import { createContext } from "react";
import AuthorizationStore from "./AuthorizationStore";
import VisitorStore from "./VisitorStore";
import TriggerStore from "./TriggerStore";
import GetGeoLocationStore from "./GetGeoLocationStore";
import ResponseCollectionStore from "./ResponseCollectionStore";

export const StoresContext = createContext({
    authorizationStore: new AuthorizationStore(),
    visitorStore: new VisitorStore(),
    triggerStore: new TriggerStore(),
    getGeoLocationStore: new GetGeoLocationStore(),
    responseCollectionStore: new ResponseCollectionStore()
})