import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import background from '../../../assets/images/background-loading.png';

export const Container = styled.div`
    padding: 10px;
    align-self: center;
    .img
    {
        height: 200px;
    }
    .title
    {
        margin: 0;
        text-align: center;
        font-weight: 550;
        font-size: 15pt;
    }
    .subtitle
    {
        text-align: center;
        margin: 0;
        font-size: 10pt;
        margin-top: 10px;
    }
    .content
    {
        font-size: 13pt;
    }
`;


export const Footer = styled.div`
    position: fixed;
    bottom: 20px;
    left: 58%;
    transform: translateX(-50%);
    .logo
    {
        height: 40px;
        align-self: center;
        margin-left: -39px;
        margin-bottom: -10px;
    }
`;