import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

const baseURL = process.env.REACT_APP_BASE_API;

var api = axios.create({ 
  baseURL : baseURL,
  headers: {
    "Content-Type": "application/json",
  }
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('@token_authorization');
    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`; 
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // 
    }
  }
);

export default api;