import React, { FC } from 'react'
import { BsInboxes } from 'react-icons/bs';

interface Props
{
    message: string;
}

const NotFound: FC<Props> = ({ message }) => {
  return (
    <div className='d-flex justify-content-center align-self-center p-4'>
        <div className='d-flex justify-content-start align-self-center'>
            <BsInboxes style={{fontSize: 26 }} /> <p style={{alignSelf: 'center', marginLeft: 10, fontWeight: 550, fontSize: 16, color: 'rgba(0,0,0,0.6)'}}>{message}</p>
        </div>
    </div>
  )
}

export default NotFound;
