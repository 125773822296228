import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Toaster } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'rsuite/dist/rsuite.min.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <Toaster 
      position='bottom-left'
      reverseOrder={false}
      gutter={1}
      toastOptions={{
        duration: 3000
      }}
    />
    <App />
  </>
);
