import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Container } from './styles/page.style';
import PageLoading from '../page_loading';
import PageTrigger from '../page_triggering/index';
import {Transition, CSSTransition, SwitchTransition, TransitionGroup} from "react-transition-group";
import { StoresContext } from '../../stores';
import { redirect } from 'react-router-dom';
import { Button } from 'rsuite';


const PageMainRendering = () => {

  const { authorizationStore, visitorStore } = useContext(StoresContext);
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    if(window.location.pathname.split('/')[3] !== null)
    {
      var tokenId = window.location.pathname.split('/')[3];
      if(tokenId)
        authorizationStore.validateAuthorizedById(tokenId)
    }
  }, []); 

  useEffect(() => {
    if(authorizationStore.error)
    {
      window.location.replace("/notoken");
    }
  }, [authorizationStore.error]); 

  useEffect(() =>
  {
    if(authorizationStore.validated)
    {
      if(visitorStore.visitor !== null || visitorStore.visitor !== undefined)
      {
        setTimeout(() =>
        {
          setTransition(true);
        }, 1000);
      }
    }
    else
      setTransition(false);
  }, [authorizationStore.validated])

  return (
    <Container>
      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={transition ? "loading" : "home"}
          addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
          classNames='fade'
        >
          {
            !transition ? <PageLoading/> : <PageTrigger/>
          }
        </CSSTransition>
      </SwitchTransition>
    </Container>
  )
}

export default observer(PageMainRendering);