import { useContext, useState } from "react";
import axios from "axios";
import { StoresContext } from "../stores";
import { toast } from "react-hot-toast";
import { IFace } from "../interfaces/IFacial";
import FaceAPI from "../apis/FaceAPI";

export const useFace = () => {

    const { responseCollectionStore, authorizationStore } = useContext(StoresContext);
    const [loading, setLoading] = useState(false);
    const [deleting, setDelete] = useState(false);

    const add = async (request: IFace) => {
        setLoading(true);
        try {
            const { data, status } = await FaceAPI.add(request);
            if (status === 200) {
                responseCollectionStore.setSelected(data);
                toast.success("Facial cadastrada com sucesso");
                setTimeout(() => authorizationStore.validateAuthorizedById(request.chaveVirtualId), 7000);
            }
            setLoading(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                toast.error("Erro ao cadastrar facial");
                responseCollectionStore.setSelected(e.response?.data);
            }
            setLoading(false);
        }
    }

    const remove = async (id: string) => {
        setDelete(true);
        try {
            const { data, status } = await FaceAPI.remove(id);
            if (status === 200) {
                responseCollectionStore.setSelected(data);
                toast.success("Facial removida com sucesso");
                authorizationStore.validateAuthorizedById(id)
            }
            setDelete(false);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                toast.error("Erro ao remover facial");
                responseCollectionStore.setSelected(e.response?.data);
                setTimeout(() => authorizationStore.validateAuthorizedById(id), 7000);
            }
            setDelete(false);
        }
    }

    return {
        add,
        remove,
        deleting,
        loading
    }

}