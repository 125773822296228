import axios, { AxiosResponse } from "axios"

const baseURL = process.env.REACT_APP_BASE_MS_MOBILE;

const api = axios.create({
  baseURL: baseURL,
})
api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('@token_mobile');
    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`
    }
    return config
  }
)

export default api;
