import axios from 'axios';
import { IAuthorization } from '../../interfaces/IVisitor';

const baseURL = process.env.REACT_APP_BASE_MS_AUTHENTICATION;

const api = axios.create({ baseURL });

export const authentication = async (autorizacaoId: string) => await api.post<IAuthorization>(`${baseURL}/virtualKeyAuthentication`, {
    autorizacaoId: autorizacaoId, 
    service: process.env.REACT_APP_SERVICE_KEY_OUTKEY,
    servicemobile: process.env.REACT_APP_SERVICE_KEY_APP,
});