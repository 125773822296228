import { FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import jwtDecode from "jwt-decode";

// pages
import PageMainRendering from '../views/page_main_rendering';
import PageNotFound from '../views/page_not_found';
import NotToken from '../components/not_token';

// interface route private
interface IPrivate {
  children: any;
}

const isAuthenticated = () => {
  const token = localStorage.getItem("@token_authorization");

  if (token) {
    const decodedToken: { exp: number } = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    const tokenExpirationTime = decodedToken.exp + 10800;

    if (tokenExpirationTime < currentTime) {
      localStorage.setItem('@refresh_data', "0x53")
      return true;
    } else {
      return true;
    }
  }
}


export const PrivateRoute: FC<IPrivate> = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to={'/login'} />
}

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'chavevirtual/login/:id'} element={<PageMainRendering />} />
        {/* <Route path={'/'} element={<PageMainRendering/>} /> */}
        {/* <Route path={'/'} element={
                  <PrivateRoute>
                    <Home/>
                  </PrivateRoute>
                } 
                /> */}
        {/* <Route path={'/token'} element={<TokenInvalid/>} /> */}
        <Route path={'*'} element={<PageNotFound />} />
        <Route path={'/notoken'} element={<NotToken />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router;