import React, { FC } from 'react'
import notoken from '../../assets/images/token.png'

const NotToken = () => {
  return (
    <div className='p-4 mt-5'>
        <div className='d-flex justify-content-center align-self-center'>
            <img src={notoken} style={{ height: 190 }} />
        </div>
        <p style={{ textAlign: 'center', fontSize: '12pt', fontWeight: 550 }}>Chave virtual não encontrada</p>
        <p style={{ textAlign: 'center', fontSize: '10pt' }}>
          Verificamos que a chave de identificação em questão não se encontra mais disponível
        </p>
    </div>
  )
}

export default NotToken;
