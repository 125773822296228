import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import background from '../../../assets/images/background-loading.png';

export const Container = styled.div`
    padding: 10px;
    .img
    {
        height: 120px;
    }
    .title
    {
        margin: 0;
        text-align: center;
        color: #004d93;
        font-weight: 550;
        font-size: 15pt;
    }
    .subtitle
    {
        text-align: center;
        margin: 0;
        font-size: 10pt;
    }
    .content
    {
        font-size: 13pt;
    }
`;